










































import {Component, Vue} from 'vue-property-decorator';
import {getCallerID} from '@/api';
import {abillsModule} from '@/store';

@Component({name: 'CallerID'})
export default class CustomersList extends Vue {
    loading = false;
    callers_data: any = {};
    interval: number = 1000;

    get get_callers() {
        return this.callers_data;
    }

    async created() {
        await abillsModule.fetch();
        await this.loadCallerIDData();
        this.interval = setInterval(() => this.load_callers(), 2000);
    }

    async load_callers() {
        const callers_data = await getCallerID();
        this.callers_data = callers_data;
    }

    async loadCallerIDData() {
        this.loading = true;

        try {
            const callers_data = await getCallerID();
            this.callers_data = callers_data;
        } catch (e) {

        } finally {
            this.loading = false;
        }
    }

    beforeDestroy() {
        clearInterval(this.interval)
    }
}

