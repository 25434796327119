












































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Header from '@/types/tables';
import {getDailyPayments, getMonthlyPaymentsExcel, searchAbillsUsers, searchAbillsUsersDownloadExcel} from '@/api';
import { Customer } from '@/types/customers';
import { saveAs } from 'file-saver';
import PaymentsCard from '@/components/PaymentsCard.vue';
import { abillsModule } from '@/store';

@Component({
    name: 'PaymentsView',
    components: {
        PaymentsCard,
    },
})
export default class PaymentsView extends Vue {
    tab = null;
    dateMenu = false;
    mainGroup = 999;
    payments = [];
    date = new Date().toISOString().slice(0, 10);
    loading = false;
    bonuses = false;
    cash = true;
    bank = true;
    external = true;
    dcs_filter: string | null = null;
    excelLoading = false;

    async created() {
        await abillsModule.fetch();
    }

    async mounted() {
        await this.loadPayments();
    }

    async loadPayments() {
        this.payments = [];
        this.loading = true;
        this.payments = await getDailyPayments(this.date, this.bonuses,
            this.cash, this.bank, this.external, this.dcs_filter, this.mainGroup === 999 ? null : this.mainGroup);
        this.loading = false;
    }

    get mainGroups() {
        return abillsModule.mainGroups;
    }


    @Watch('date')
    async onDateChanged() {
        await this.loadPayments();
    }

    @Watch('mainGroup')
    async onMainGroupChanged() {
        await this.loadPayments();
    }

    @Watch('bonuses')
    async onBonusesChanged() {
        await this.loadPayments();
    }

    @Watch('tab')
    async onTabChanged(value: number) {
        switch (value) {
            case 0:
                this.bank = true;
                this.cash = true;
                this.external = true;
                this.dcs_filter = null;
                await this.loadPayments();
                break;
            case 1:
                this.bank = false;
                this.cash = false;
                this.external = true;
                this.dcs_filter = 'arMax';
                await this.loadPayments();
                break;
            case 2:
                this.bank = false;
                this.cash = false;
                this.external = true;
                this.dcs_filter = 'CityPay';
                await this.loadPayments();
                break;
            case 3:
                this.bank = false;
                this.cash = false;
                this.external = true;
                this.dcs_filter = '';
                await this.loadPayments();
                break;
            case 4:
                this.bank = true;
                this.cash = false;
                this.external = false;
                this.dcs_filter = null;
                await this.loadPayments();
                break;
        }
    }

    async downloadExcel() {
        this.excelLoading = true;
        try {
            const blob = await getMonthlyPaymentsExcel(this.date,
                this.bonuses,
                this.cash,
                this.bank,
                this.external,
                this.dcs_filter,
                this.mainGroup === 999 ? null : this.mainGroup);
            saveAs(blob, `Все платежи ${this.date}.xlsx`);
        } catch (e) {
        } finally {
            this.excelLoading = false;
        }
    }
}
