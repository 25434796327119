


























import { Component, Vue , Prop, Watch} from 'vue-property-decorator';
import Header from '@/types/tables';
import { getCustomers, getMonthlyPaymentsExcel, searchAbillsUsersDownloadExcel } from '@/api';
import { Customer, DetailedCustomer } from '@/types/customers';
import { abillsModule } from '@/store';
import { saveAs } from 'file-saver';
import { formatISO9075 } from 'date-fns';

@Component({name: 'PaymentsCard'})
export default class PaymentsCard extends Vue {
    @Prop({ })
    title!: string;

    @Prop({ })
    loading!: boolean;

    @Prop({ })
    payments!: [];

    @Prop({ })
    date!: Date;

    search  = '';


    headers: Header[] = [
        {text: 'Дата', value: 'date' },
        {text: 'Логин', value: 'id'},
        {text: 'uid', value: 'uid'},
        {text: 'Сумма', value: 'sum'},
        {text: 'Описание', value: 'dsc'},
        {text: 'Дополлнительно', value: 'ext_id'},
        {text: 'Кто внес', value: 'admin'},
    ];

    get sum(){
        return this.payments.reduce((a, b) => +a + +b['sum'], 0);
    }

    get avgCheck(){
        return this.sum / this.payments.length
    }

}
